import React from "react";

export const ArrowRight = () => {
  return (
    <svg width="60px" height="39px" viewBox="0 0 98 39" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Arrow</title>
      <g transform="translate(-1080.000000, -4699.000000)">
        <g transform="translate(-3.000000, 4470.000000)">
          <g transform="translate(955.000000, 49.000000)">
            <path
              d="M225,199.532513 C225,199.922671 224.60727,200.31283 224.214539,200.31283 L131.618203,200.31283 L147.981971,216.56942 C148.243791,216.829525 148.243791,217.349736 147.981971,217.739895 C147.851061,217.869947 147.58924,218 147.45833,218 C147.32742,218 147.0656,217.869947 146.93469,217.739895 L129.13091,200.052724 C129,199.922671 129,199.922671 129,199.792619 C129,199.662566 129,199.532513 129,199.532513 C129,199.40246 129,199.272408 129,199.272408 C129,199.142355 129.13091,199.142355 129.13091,199.012302 L147.0656,181.195079 C147.32742,180.934974 147.851061,180.934974 148.243791,181.195079 C148.505611,181.455185 148.505611,181.975395 148.243791,182.365554 L131.618203,198.752197 L224.214539,198.752197 C224.60727,198.752197 225,199.142355 225,199.532513 Z"
              stroke="#180044"
              strokeWidth="1.5"
              fill="#180044"
              transform="translate(177.000000, 199.500000) rotate(-180.000000) translate(-177.000000, -199.500000) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
