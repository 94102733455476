import React, { FunctionComponent, useState } from "react";
import { ImageInterface, PortableText, MultiLineHeadline, SanityColorList, Slug } from "../../types/SanityTypes";
import { ColorVariations } from "../../types/ComponentTypes";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "../Link";
import { ArrowRight } from "../../images/icons/arrow-right-two";
import Container from "react-bootstrap/Container";
import FadeIn from "../../components/FadeIn";
import ModalBlock, { ModalBlockInterface } from "../../components/Modal";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";

export interface PromoBlockInterface {
  promoBlockType: {
    name: string;
  };
  bgColor: SanityColorList;
  headline: MultiLineHeadline;
  _rawImage?: ImageInterface;
  _rawIntroduction: PortableText;
  introduction?: PortableText;
  ctaLabel: string;
  ctaReference?: {
    _type?: string;
    _id?: string;
    __typename?: string;
    slug?: {
      current: string;
    };
    headline?: MultiLineHeadline;
    _rawIntroduction?: PortableText;
    surveyBlock?: {
      submitCTA: string;
      questions: {
        question: string;
        questionType: string;
      }[];
    };
    parentSection?: {
      slug: {
        current: string;
      };
      parentSection?: Slug;
    };
  };
}

const PromoBlock: FunctionComponent<PromoBlockInterface> = ({
  promoBlockType,
  bgColor,
  headline,
  _rawImage,
  _rawIntroduction,
  introduction,
  ctaLabel,
  ctaReference
}) => {
  const [showModal, setShowModal] = useState(false);

  const promoIntro = _rawIntroduction || introduction;

  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const bigUrl = _rawImage
    ? (urlBuilder
        .image(_rawImage?.asset)
        .auto("format")
        .quality(80)
        .width(1280)
        .height(720)
        .format("webp")
        .url() as string) + "&fm=webp"
    : undefined;

  const colorVariations: ColorVariations = {
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--blue-bright-color)",
      gradientDegrees: "90"
    },
    Green: {
      primaryGradientColor: "var(--green-color)",
      secondaryGradientColor: "var(--green-bright-color)",
      gradientDegrees: "90"
    }
  };

  const renderGradient = `linear-gradient(${colorVariations[bgColor.title].gradientDegrees}deg, ${
    colorVariations[bgColor.title].primaryGradientColor
  } 0%, ${colorVariations[bgColor.title].secondaryGradientColor} 94%)`;

  const linkColor = bgColor.title === "Blue" ? "promo-block-link-green" : "promo-block-link-blue";
  const textColor = bgColor.title === "Blue" ? "white" : "var(--blue-bright-color)";

  const parentPath = ctaReference?.parentSection?.parentSection
    ? `${ctaReference?.parentSection?.parentSection.slug.current}/${ctaReference?.parentSection?.slug.current}`
    : ctaReference?.parentSection?.slug.current;

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const renderCTA = () => {
    if (ctaReference?.__typename === "SanityModalCTABlock" || ctaReference?._type === "modalCTABlock") {
      return (
        <>
          <button className={linkColor} onClick={handleShow} id={`promoBlockLink-${ctaLabel}`}>
            <div style={{ marginBottom: "50px" }}>
              {ctaLabel}
              <ArrowRight />
            </div>
          </button>
          <ModalBlock
            data={(ctaReference as ModalBlockInterface["data"]) || ""}
            showModal={showModal}
            handleClose={handleClose}
          />
        </>
      );
    } else {
      const ctaUrl = ctaReference?.parentSection
        ? `${parentPath}/${ctaReference?.slug?.current}`
        : ctaReference?.slug?.current;

      return (
        <>
          {ctaUrl && (
            <Link
              _id={ctaReference?._id}
              className={linkColor}
              to={ctaUrl}
              id={`promoBlockLink-${ctaLabel}`}
              {...(ctaReference?.__typename === "SanityNewsletterSignUp" && { "data-analytics-event57": "" })}
              {...(ctaReference?.__typename === "SanityArticle" && { "data-analytics-event4": "" })}
            >
              {ctaLabel}
              <ArrowRight />
            </Link>
          )}
        </>
      );
    }
  };

  const renderContent = (sectionClasses: string, dataCy: string, textClasses: string, linkClasses: string) => {
    return (
      <section className={sectionClasses} data-testid={dataCy} style={{ background: renderGradient, color: textColor }}>
        <Container fluid>
          <div className="row no-gutters">
            <div className="col-12">
              <h2
                id={`header1-${ctaLabel}`}
                aria-label={
                  headline.primaryText + " " + (headline.secondaryText || "") + " " + (headline.tertiaryText || "")
                }
              >
                <FadeIn axis={"x"} direction={-400} duration={1.5} id={`header1-${ctaLabel}`}>
                  {headline.primaryText}
                </FadeIn>
                {headline.secondaryText && (
                  <FadeIn axis={"x"} direction={-500} duration={2} id={`header2-${ctaLabel}`}>
                    <span style={{ WebkitTextStrokeColor: textColor }} id={`header2-${ctaLabel}`} aria-hidden="true">
                      {headline.secondaryText}
                    </span>
                  </FadeIn>
                )}
                {headline.tertiaryText && (
                  <FadeIn axis={"x"} direction={-400} duration={1.5} id={`header1-${ctaLabel}`}>
                    {headline.tertiaryText}
                  </FadeIn>
                )}
              </h2>
            </div>
            <div className={textClasses}>
              <FadeIn axis={"y"} direction={100} duration={1.5} id={`promoBlockCopy-${ctaLabel}`}>
                <div className="body-copy" id={`promoBlockCopy-${ctaLabel}`}>
                  {promoIntro && <BlockContent blocks={promoIntro} />}
                </div>
              </FadeIn>
            </div>
            <div className={linkClasses}>
              <div className="promo-block-position">
                <FadeIn axis={"x"} direction={300} duration={2} id={`promoBlockLink-${ctaLabel}`}>
                  {renderCTA()}
                </FadeIn>
              </div>
            </div>
          </div>
        </Container>
      </section>
    );
  };

  const setPromoImageBackground =
    ctaReference?.__typename === "SanityLandingPage" ? "promo-campaign-image" : "promo-content-background";

  return promoBlockType.name === "Promotion (With Image)" ? (
    <>
      <style>
        {`
          @media (min-width: 768px) {
            .${setPromoImageBackground} .container-fluid {
              background: url(${bigUrl}) no-repeat;
              background-size: contain;
              background-position: 300px;
            }
          }
          @media (min-width: 992px) {
            .${setPromoImageBackground} .container-fluid {
              background-position: 98%;
            }
          }
        `}
      </style>
      {renderContent(
        `promo-block-tick ${setPromoImageBackground}`,
        "promo-block-tick",
        "col-12 col-md-7 offset-lg-1",
        "col-12 col-md-5 col-lg-4 ball"
      )}
    </>
  ) : (
    <>{renderContent("promo-block", "promo-block", "col-12 col-sm-8", "col-12 col-sm-4 ball")}</>
  );
};

export default PromoBlock;
