import React, { FunctionComponent, useRef, useEffect } from "react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export interface FadeInInterface {
  children: unknown;
  axis: string;
  direction: number;
  duration: number;
  id?: string;
}

const FadeIn: FunctionComponent<FadeInInterface> = ({ children, axis, direction, duration, id }) => {
  gsap.registerPlugin(ScrollTrigger);
  id = id?.replace(/ /g, "");
  const componentRef = useRef(null);

  useEffect(() => {
    axis === "y" &&
      gsap.to(componentRef.current, {
        scrollTrigger: {
          trigger: componentRef.current,
          start: "top top",
          end: "bottom bottom",
          scrub: true,
          pin: true,
          anticipatePin: 1,
          id: id
        },
        opacity: 1,
        ease: Power3.easeOut,
        duration: duration
      });
    axis === "x" &&
      gsap.to(componentRef.current, {
        scrollTrigger: {
          trigger: componentRef.current,
          start: "left left",
          end: "right right",
          scrub: true,
          pin: true,
          anticipatePin: 1,
          id: id
        },
        opacity: 1,
        ease: Power3.easeOut,
        duration: duration
      });
  }, [componentRef, axis, direction, duration, id]);
  return (
    <span className="animated-chunk" ref={componentRef}>
      {children}
    </span>
  );
};

export default FadeIn;
