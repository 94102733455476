import React, { FunctionComponent, useState, ChangeEvent, useContext } from "react";
import { Form } from "react-bootstrap";
import { LocalizedContext } from "../../services/LocalizedContextService";

export type RangeSliderInterface = {
  question: string;
  questionNumber: string;
  values: Record<string, unknown>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RangeSlider: FunctionComponent<RangeSliderInterface> = ({ question, questionNumber, handleChange }) => {
  const { sliderLabels } = useContext(LocalizedContext).sanityLabels || {};

  const rangeValues: Record<string, string | undefined> = {
    1: sliderLabels?.stronglyDisagree || "Strongly Disagree",
    2: sliderLabels?.disagree || "Somewhat Disagree",
    3: sliderLabels?.neither || "Neither Agree nor Disagree",
    4: sliderLabels?.agree || "Somewhat Agree",
    5: sliderLabels?.stronglyAgree || "Strongly Agree"
  };

  const [range, setRange] = useState("3");

  const handleQuestionUpdate = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setRange(event.target.value);
    handleChange(event as ChangeEvent<HTMLInputElement>);
  };

  return (
    <Form.Group controlId={questionNumber}>
      <Form.Label>{question} *</Form.Label>
      <p>{rangeValues[range]}</p>
      <Form.Control
        value={range}
        type="range"
        min={1}
        max={5}
        step={1}
        onChange={event => handleQuestionUpdate(event)}
        name={questionNumber}
        data-testid="slider-question"
      />
    </Form.Group>
  );
};

export default RangeSlider;
