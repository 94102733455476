import React, { FunctionComponent, FormEvent, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import RangeSlider from "../RangeSlider";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";

export interface SurveyBlockInterface {
  data: {
    submitCTA: string;
    beyondSport: boolean;
    questions: {
      question: string;
      questionType: string;
    }[];
  };
  target?: string;
  submit: (e: FormEvent) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: Record<string, unknown>;
}

const SurveyBlock: FunctionComponent<SurveyBlockInterface> = ({ data, target, submit, handleChange, values }) => {
  const { sliderLabels } = useContext(LocalizedContext).sanityLabels || {};

  const renderQuestions = () => {
    return data.questions.map((item, index) => {
      const questionNumber = `Q${index + 1}`;
      if (item.questionType === "text") {
        return (
          <div id={`${index}`} key={index} data-testid="survey-text-question" className="question">
            <Form.Group controlId={questionNumber}>
              <Form.Label>{item.question} *</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={2}
                onChange={handleChange}
                name={`${questionNumber}`}
                value={values.questionNumber as string}
                data-testid="text-question"
              />
            </Form.Group>
          </div>
        );
      }
      if (item.questionType === "slider") {
        return (
          <div id={`${index}`} key={index} data-testid="survey-slider-question" className="question">
            <RangeSlider
              question={item.question}
              questionNumber={questionNumber}
              values={values}
              handleChange={handleChange}
            />
          </div>
        );
      }
      if (item.questionType === "yesNo") {
        return (
          <div id={`${index}`} key={index} data-testid="survey-slider-question" className="question">
            <Form.Label>{item.question} *</Form.Label>
            <div>
              <input
                type="radio"
                id={sliderLabels?.yes}
                name={`${questionNumber}`}
                value={sliderLabels?.yes}
                onChange={handleChange}
                required
              />
              <label>{sliderLabels?.yes}</label>
            </div>
            <div>
              <input
                type="radio"
                id={sliderLabels?.no}
                name={`${questionNumber}`}
                value={sliderLabels?.no}
                onChange={handleChange}
              />
              <label>{sliderLabels?.no}</label>
            </div>
            <div>
              <input
                type="radio"
                id={sliderLabels?.unsure}
                name={`${questionNumber}`}
                value={sliderLabels?.unsure}
                onChange={handleChange}
              />
              <label>{sliderLabels?.unsure}</label>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="survey-block">
      <Form onSubmit={e => submit(e)} aria-label="Survey" data-testid="survey">
        {target && <input type="hidden" name="target" value={target} data-testid="hidden-target-id" />}
        <input type="hidden" name="beyondSport" value={data.beyondSport?.toString()} data-testid="hidden-survey-type" />
        <div className="questions-block">{renderQuestions()}</div>
        <div className="button-position">
          <Button type="submit" variant="outline-light" className="submit-button" data-testid="survey-submit-button">
            {data.submitCTA}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SurveyBlock;
